/***************
メインカラー：#000000
****************/
$def-letter-spc: -0.02em;
$color-midashi: #B66C84;
$color-body: #222222;
$color-inner-link: #FF006C;
$color-accent: #C691B1;
$common-width: 1080px;

#fv {
  width: 100%;

  .fv-wrapper {
    height: 406px;

    .logo-wrapper {
      width: 100%;
      text-align: center;
      margin-top: -82px;

      .logo {
        width: 241px;
        margin-top: 145px;
      }

      .sub-title{
        font-size: 13px;
        color: #838383;
        margin-top: 40px;
      }
    }//.logo-wrapper

    .mask {
      height: 406px;
    }
  }//.fv-wrapper
  .fv-wrapper-under{
    position: relative;
    padding-top: 60px;

    .vertical-wrapper {
      transform: rotate(-90deg);
      position: absolute;
      top: 120px;
      left: 50px;

      .border{
        width: 85px;
        height: 1px;
        background: #ED89B7;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }

      .scroll{
        color: #ED89B7;
        font-size: 10px;
        letter-spacing: $def-letter-spc;
        display: inline-block;
        vertical-align: middle;
      }
    }//.vertical-wrapper

    .slogan {
      font-size: 44px;
      color: $color-midashi;
      letter-spacing: 0.12em;
      text-align: center;
    }//.slogan
    .sub-slogan {
      margin-top: 15px;
      font-size: 20px;
      color: #000000;
      letter-spacing: 0.03em;
      text-align: center;
    }//.sub-slogan

    .slogan-desc {
      width: 1020px;
      text-align: center;
      margin: 60px auto 0 auto;
      font-size: 15px;
      line-height: 2.2;
      color: #222222;

      >a {
        color: #B66C84;
        text-decoration: underline;
        padding: 0 2px;
      }
      .notice {
        color: #4A4A4A;
        font-size: 12px;
        margin-top: 16px;
      }//.notice

      &.under {
        padding-bottom: 120px;

        img {
          width: 435px;
        }

        .desc {
          margin: 0 auto;
          width: 1020px;
          text-align: center;
          padding-top: 20px;

          .appeal-button {
            margin: 0 auto;
            margin-top: 50px;
            width: 337px;
          }//.appeal-button
        }//.right
      }//.under
    }//.slogan-desc
  }//.fv-wrapper-under
}//#fv


#main-menu {
  // margin-top: -50px;

  .main-menu-wrapper {
    width: 1100px;
    margin: 0 auto;
    .menu-box {
      width: calc((100% - 40px * 2) / 3);
      width: -webkit-calc((100% - 40px * 2) / 3);
      float: left;

      .thumbnail-wrapper {
        width: 100%;
        height: 220px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        &.about {
          background-image: url("/wp-content/themes/genius/assets/img/pc/img-about.png");
        }
        &.courses {
          background-image: url("/wp-content/themes/genius/assets/img/pc/img-courses.png");
        }
        &.contact {
          background-image: url("/wp-content/themes/genius/assets/img/pc/img-contact.png");
        }
        .mask {
          width: 100%;
          height: 100%;
          background: rgb(255,255,255);
          background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        }//.mask
      }//.thumbnail-wrapper

      &:nth-child(2n) {
        margin: 0 39px;
      }

      .line {
        background-color: #D6D6D6;
        width: 1px;
        height: 100px;
        margin: -50px auto 35px auto;
      }
      .title-en{
        font-size: 33px;
        color: #994D52;
        text-align: center;
        margin-bottom: 10px;
      }
      .title {
        font-size: 16px;
        letter-spacing: 0.05em;
        color: #3E3B39;
        text-align: center;
      }//.title

    }//.menu-box
  }//.main-menu-wrapper
}//#main-menu



#recent-news {
  width: 1100px;
  margin: 90px auto;
  background-color: #FCF9F9;
  .news-wrapper {
    padding: 28px 30px;
    .left {
      color: #B66C84;
      font-size: 15px;
      text-align: left;
      width: 150px;
      float: left;
      padding-top: 7px;

    }//.left
    .right {
      text-align: left;
      width: calc(100% - 150px);
      width: -webkit-calc(100% - 150px);
      float: right;

      .date {
        font-size: 11px;
        color: #5D5D5D;
        letter-spacing: 0.05em;
        margin-bottom: 2px;
      }//.date
      .title {
        font-size: 14px;
        color: #222222;
        a {
          &:hover {
            text-decoration: underline;
            color: $color-inner-link;
          }
        }
      }//.title
    }//.right
  }//.news-wrapper
}//#recent-news



#course {
  .title {
    text-align: center;
    color: #AB134D;
    font-size: 33px;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 34px;
    color: $color-midashi;
    letter-spacing: 0.25em;
    text-align: center;
    width: 100%;
  }

  .course-outer {
    margin-top: 200px;
    width: 100%;
    background: rgb(245,245,242);
    background: -moz-linear-gradient(180deg, rgba(245,245,242,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(245,245,242,1) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(245,245,242,1) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f2",endColorstr="#ffffff",GradientType=1);
  }

  .course-wrapper {
    width: 1100px;
    margin: 140px auto 0px auto;

    .course-box {
      width: calc((100% - 40px * 2) / 3);
      width: -webkit-calc((100% - 40px * 2) / 3);
      float: left;
      background-color: #FFF;
      margin-top: -120px;

      .wrapper {
        padding: 50px 50px;

        .desc {
          font-size: 13px;
          color: #222222;
          margin-bottom: 43px;
          line-height: 180%;

          a {
            text-decoration: underline;
            color: #B66C84;
          }
        }
      }

      h3 {
        text-align: center;
        color: #000000;
        font-size: 18px;
        letter-spacing: 0.02em;
      }

      .thumbnail {
        width: 100%;
        height: 240px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }//.thumbnail-wrapper

      &:nth-child(2n) {
        margin: 0 39px;
        margin-top: -120px;
      }

      .row {
        margin-bottom: 30px;
        .title {
          font-size: 13px;
          color: #9D988B;
          text-align: left;
          margin-bottom: 10px;
        }
        .value {
          font-size: 14px;
          color: #000000;
          text-align: left;
          line-height: 170%;
          .notice {
            font-size: 12px;
            color: #3B3B3B;
            margin-top: 20px;
            line-height: 160%;
          }
        }//.value
      }

      .appeal-button {
        margin-top: 50px;
      }
      .common-button {
        margin-top: 25px;
      }

    }//.course-box
  }//.course-wrapper
}//#course


#profile {

  .title {
    font-size: 34px;
    color: #000000;
    margin-bottom: 10px;
  }

  h2 {
    color: #AB134D;
    letter-spacing: 0.25em;
    font-size: 18px;
    margin-bottom: 35px;
  }

  .about-logo {
    width: 1000px;
    height: 490px;
    margin: 30px auto 70px auto;
    background-image: url("/wp-content/themes/genius/assets/img/pc/bg-aboutlogo.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;

    .left {
      float: left;
      width: 300px;

      img {
        width: 223px;
        margin-top: 140px;
      }
    }//.left
    .right {
      padding-top: 60px;
      width: calc(100% - 350px);
      width: -webkit-calc(100% - 350px);
      float: right;
      color: #363333;
      font-size: 14px;
      line-height: 190%;
    }//.right
  }//.about-logo




  .about-teacher {
    background: rgb(255,250,252);
    background: -moz-linear-gradient(180deg, rgba(255,250,252,1) 0%, rgba(252,249,249,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,250,252,1) 0%, rgba(252,249,249,1) 100%);
    background: linear-gradient(180deg, rgba(255,250,252,1) 0%, rgba(252,249,249,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffafc",endColorstr="#fcf9f9",GradientType=1);
    padding-top: 65px;
    padding-bottom: 45px;

    .teacher-wrapper{
      margin: 0 auto;
      width: 1000px;

      .left {
        width: calc(100% - 350px);
        width: -webkit-calc(100% - 350px);
        float: left;
        color: #363333;
        font-size: 14px;
        line-height: 2.1;

        .title{
          margin-bottom: -10px;
        }

        .appeal-button {
          width: 330px;
          margin-top: 35px;
        }
      }//.left
      .right {
        float: right;
        width: 300px;
        text-align: center;
        margin-right: -22px;
        margin-top: 17px;

        img {
          width: 250px;
          margin-top: -15px;
        }
      }//.right
    }//.teacher-wrapper
  }//.about-logo
}//#profile

#news {
  width: 100%;
  padding-bottom: 30px;

  .line {
    width: 1px;
    height: 100px;
    margin: 0 auto;
    background-color: #E8A2C2;
    margin-bottom: 30px;
  }//.line

  .title {
    text-align: center;
    font-size: 33px;
    color: #AB134D;
  }

  h2 {
    font-size: 34px;
    color: #994D52;
    letter-spacing: 0.25em;
    text-align: center;
    width: 100%;
    margin: 5px 0 30px 0;
  }
  .news-wrapper {
    width: 1100px;
    margin: 40px auto 65px auto;

    .news-box {
      width: calc((100% - 40px * 2) / 3);
      width: -webkit-calc((100% - 40px * 2) / 3);
      float: left;
      background-color: #FFF;

      .wrapper {
        padding: 50px 50px;

        .desc {
          font-size: 13px;
          color: #222222;
          margin-bottom: 30px;
          line-height: 180%;
        }
      }

      .thumbnail {
        width: 100%;
        height: 240px;
        background-image: url("/wp-content/themes/genius/assets/img/common/news-01.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #F0F0F0;
      }//.thumbnail-wrapper

      &:nth-child(2) {
        margin: 0 39px;
      }

      .date {
        color: #5D5D5D;
        font-size: 11px;
        letter-spacing: 0.02em;
      }
      .title {
        font-size: 15px;
        color: #000000;
        margin: 15px 0 10px 0;
        text-align: left;
        &:hover {
          color: $color-inner-link;
          text-decoration: underline;
        }
      }

      .common-button {
        margin-top: 12px;
      }

    }//.news-box
    .sp-only {

    }
    .operation-area {
      width: 280px;
      margin: 60px auto 0 auto;
    }//.operation-area
  }//.news-wrapper
}//#news


#footer-menu {
  background: rgb(253,247,250);
  background: -moz-linear-gradient(180deg, rgba(253,247,250,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(253,247,250,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(253,247,250,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdf7fa",endColorstr="#ffffff",GradientType=1);
  margin-top: 80px;
  padding-bottom: 70px;

  .footer-wrapper {
    width: 1130px;
    margin: 0 auto;
    .menu-box {
      width: calc((100% - 60px) / 2);
      width: -webkit-calc((100% - 60px) / 2);
      float: left;
      margin-top: -80px;

      &:first-child {
        margin-right: 60px;
      }

      img {
        width: 100%;
      }
      .line {
        width: 1px;
        height: 50px;
        background-color: #E8A2C2;
        margin: -5px auto 0 auto;
      }//.line
      .title {
        font-size: 18px;
        color: $color-midashi;
        // letter-spacing: 0.3em;
        text-align: center;
        width: 100%;
        margin: 45px 0 30px 0;
        .en {
          font-size: 33px;
          margin-bottom: 5px;
        }
      }//.title
      .desc {
        font-size: 13px;
        line-height: 190%;
        color: #313131;
        letter-spacing: 0.02em;
      }//.desc
    }//.menu-box
  }//.footer-wrapper
}//#footer-menu

.insta-wrapper{
  width: 100%;
  margin: 0 auto;
  margin-top: 155px;
  .meks-instagram-widget{
    div{
      max-width: 20%;
    }
  }
}//.insta-wrapper
#meks_instagram-2 img {
  max-width: 100%;
}
footer {
  background: #ffffff;
}